import React from 'react';
import classNames from 'classnames';

const ns = `product-notice`;

const ProductNotice = ({ productNotice, className }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ className }`]: className,
	});

	return (
		<p className={rootClassnames}>
			{productNotice}
		</p>
	);
};

export default ProductNotice;
